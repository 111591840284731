<template>
  <div class="menu_index">
    <div class="container">
      <div class="head">
        <div
          class="item"
          :class="headTabActive == item.id ? 'active' : ''"
          @click="clickItem(item)"
          v-for="item in tabHead"
          :key="item.id"
        >
          {{ item.value }}
        </div>
      </div>

      <div>
        <div class="input_flex">
          <div class="input-with">
            <el-button type="primary" @click="addbanner()">新增</el-button>
          </div>
          <div style="float: right">
            <div class="seach_box">
              <el-input
                placeholder="请输入名称"
                v-model="queryInfo.queryConditions"
                class="input-with-select"
              >
                <el-button class="seach" slot="append" @click="changeSearchangeSearch">搜索</el-button>
              </el-input>
            </div>
          </div>
        </div>
        <el-table
          v-loading="loading"
          element-loading-text="加载中..."
          element-loading-spinner="el-icon-loading"
          :data="listData"
          stripe
          style="width: 100%"
          class="in_table"
          row-key="id"
          :default-expand-all="false"
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >
          <!-- <el-table-column label="序号" align="center" prop="id"></el-table-column> -->

          <el-table-column label="名称" prop="authName" align="center"></el-table-column>
          <el-table-column label="图标" align="center">
            <template v-slot="{ row }">
              <div>
                <i :class="row.icon" class="iconfont" />
              </div>
            </template>
          </el-table-column>

          <el-table-column label="类型 " prop="companyFullName" align="center">
            <template v-slot="{ row }">
              <span v-if="row.type == 0" class="caidan">菜单</span>
              <span v-if="row.type == 1" class="mulu">一级目录</span>
              <span v-if="row.type == 2" class="mulu">二级目录</span>
              <span v-if="row.type == 3" class="buttom">按钮</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="上级菜单" prop="content" align="center">
              <template v-slot="{ row }">
               <span></span>
              </template>
            </el-table-column> -->
          <el-table-column label="菜单URL" prop="content" align="center">
            <template v-slot="{ row }">
              <span>{{ row.url }}</span>
            </template>
          </el-table-column>
          <el-table-column label="授权标识" prop="content" align="center">
            <template v-slot="{ row }">
              <span>{{ row.perms }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" prop="createTime" align="center">
            <template v-slot="{ row }">
              <span class="edit" @click="editclick(row)">编辑</span>
              <span class="frozen" @click="carouselDelete(row)">删除</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>


    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      :title="dialogType ? '编辑菜单' : '新增菜单'"
      :visible.sync="dialogVisible.menu"
      width="30%"
      @close="handleCloseMenu"
      center
    >
      <el-form ref="form" :model="menuObj" :rules="menuRules" label-width="130px" :inline="true">
        <el-form-item label="菜单类型：" prop="type">
          <el-radio-group v-model="menuObj.type">
            <el-radio v-for="item in menuTypeList" :key="item.value" :label="item.value">{{
              item.label
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <div style="display: flex; flex-direction: column; align-items: center">
          <el-form-item :label="`${menuTypeList[menuName].label}名称：`" prop="authName">
            <el-input v-model="menuObj.authName" placeholder="请输入" />
          </el-form-item>

          <el-form-item label="上级菜单：">
            <popup-tree-input
              :data="popupTreeData"
              :props="popupTreeProps"
              :prop="parentName == '' ? '顶级菜单' : parentName"
              :currentChangeHandle="handleTreeSelectChange"
            >
            </popup-tree-input>
          </el-form-item>
          <el-form-item label="菜单路由：" prop="url" v-if="menuObj.type != 3 && menuObj.type != 2">
            <el-input v-model="menuObj.url" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="授权标识：" prop="perms">
            <el-input v-model="menuObj.perms" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="接口路径：" prop="apiPath" v-if="menuObj.type == 3">
            <el-input v-model="menuObj.apiPath" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="菜单图标：" v-if="menuObj.type != 3 && menuObj.type != 2">
            <el-input v-model="menuObj.icon" placeholder="(如:icon-jr-icon-home)" />
          </el-form-item>
          <div style="margin-left: 30px">菜单修改之后，重新登录才生效</div>
        </div>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="dialogVisible.menu = false">取 消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { getAuthAll,getAll, addAuthInfo, editAuthInfo, deleteAuthInfo, getAuthInfoById } from '@/api/menu'
import PopupTreeInput from '../../../components/PopupTreeInput'

const defaultMenuObj = Object.freeze({
  type: 0, //菜单类型
  authName: '', // 菜单/目录名称
  parentId: 0, // 上级菜单
  url: '', // 菜单路由
  icon: '', // 菜单图标
  perms: '', //授权
  apiPath: '', //接口路径

})
export default {
  name: 'menuindex',
  components:{PopupTreeInput},
  computed: {
    menuName() {
      return this.menuTypeList.findIndex((item) => {
        return item.value === this.menuObj.type
      })
    }
  },
  data() {
    return {
      // adminUser: { ...defaultQueryInfo },
      queryInfo: {
        pageNum: 1,
        pageSize: 10
      },
      dialogType: 0, // 菜单新增
      dialogVisible: {
        menu: false, // 菜单弹窗
        button: false // 按钮弹窗
      },
      hasChildren: true,

      listData: [],
      menuTypeList: [
        {
          label: '菜单',
          value: 0
        },
        {
          label: '一级目录',
          value: 1
        },
        {
          label: '二级目录',
          value: 2
        },
        {
          label: '按钮',
          value: 3
        }
      ],
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,

      tabHead: [
        {
          value: '总后台',
          id: 1
        },
        {
          value: '合作伙伴后台',
          id: 2
        }
      ],
      // tab类名控制
      headTabActive: 2,

      listrole: [],
      menuObj: { ...defaultMenuObj },
      menuRules: {
        type: [{ required: true, message: '请选择菜单类型', trigger: 'change' }],
        authName: [{ required: true, message: '请输入菜单名称', trigger: 'blur' }],
        perms: [{ required: true, message: '请输入授权标识', trigger: 'blur' }],
        url: [{ required: true, message: '请输入菜单路由', trigger: 'blur' }],
        apiPath: [{ required: true, message: '请输入接口路由', trigger: 'blur' }]
      },
      parentName: '', //子组件需要显示字段名
      popupTreeProps: {
        label: 'authName',
        children: 'children'
      },
      popupTreeData: []
    }
  },
  created() {
    this.search()
    this.getAll()
  },
  methods: {
    //查询一览数据
    async search() {
      this.loading = true
      const { data: res } = await getAuthAll(this.queryInfo)
      if (res.resultCode === 200) {
        this.listData = res.data.list
        this.total = res.data.total
        this.loading = false
      }
    },
    // 菜单树选项
  async  getAll(){
    const {data:res}=await getAll()
    if(res.resultCode==200){
        this.popupTreeData=res.data
    }
    },

    // 点击头部table栏
    clickItem(item) {
      this.headTabActive = item.id
      // console.log(item.id)
    },

    //新增菜单
    addbanner() {
      this.dialogVisible.menu = true
      this.parentName =''

      this.menuObj ={ ...defaultMenuObj }
    },
    //编辑
    editclick(row) {
      this.getAuthInfoById(row.id)
      this.dialogVisible.menu = true
      this.dialogType = 1
    },
      // 菜单树选中
      handleTreeSelectChange(data) {
      this.menuObj.parentId = data.id
      this.parentName = data.authName
    },
    handleCloseMenu() {
      this.$refs.form.resetFields()

      this.dialogVisible.menu = false

      Object.assign(this.menuObj, defaultMenuObj)
    },
    //banner详情
    async getAuthInfoById(id) {
      const query = {
        id: id
      }
      const res = await getAuthInfoById(query)
      if (res.data.resultCode == 200) {
        this.menuObj = res.data.data
        this.menuObj.type= this.menuObj.type*1
      }
    },
    carouselDelete(row) {
      const query = {
        id: row.id
      }
      this.$alert('您是否确认操作？')
        .then(function () {
          return deleteAuthInfo(query)
        })
        .then(() => {
          this.search()
          this.$message.success('操作成功')
        })
        .catch(() => {})
    },

    //新增编辑
    submitForm() {
      console.log(this.menuObj)
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (!this.menuObj.id) {
            addAuthInfo(this.menuObj).then((response) => {
              if (response.data.resultCode == 200) {
                this.$message.success('新增成功')
                this.dialogVisible.menu =  false
                this.search()
                this.getAll()
              } else {
                this.$message.error(response.data.message)
              }
            })
          } else {
            editAuthInfo(this.menuObj).then((response) => {
              if (response.data.resultCode == 200) {
                this.$message.success('编辑成功')
                this.dialogVisible.menu = false
                this.search()
                this.getAll()
              } else {
                this.$message.error(response.data.message)
              }
            })
          }
        }
      })
    },

    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    },
    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
.menu_index {
  background: #fff;
  padding: 0px 20px;
  .container {
    height: calc(100vh - 150px);
    overflow: auto;
    &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
  }
}
.head {
  // width: 100%;
  height: 47px;

  box-sizing: border-box;
  margin: 0 72px 0 0px;
  padding-left: 10px;
  display: flex;
  background: #fafafa;
  .item {
    width: 126px;
    color: #999999;
    margin-right: 50px;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    padding: 0px 20px;
  }

  .active {
    color: #4e93fb;
    font-size: 20px;
    background: #fff;
    border-radius: 1px;
  }
}
.seach_box {
  display: flex;
  margin: 15px 0;

  .input-with-select {
    width: 400px;
  }

  .seach {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}
.office {
  display: inline-block;
  background: #edf4ff;
  border: 1px solid #559eff;
  color: #4e93fb;
  border-radius: 2px;
  font-size: 12px;
  padding: 0px 3px;
}
.span_div {
  font-size: 14px;
  color: #7c7f8e;
  margin-right: 12px;
}
.input_flex {
  display: flex;
  align-items: center;
}

.input-with {
  width: 100%;
  overflow: hidden;

  .input-with-select {
    width: 400px;
    float: right;
  }

  .seach {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}

.in_table {
  width: 100%;
  margin-top: 20px;

  .img_url {
    width: 42px;
    height: 42px;
    border-radius: 4px;
  }
}
.edit {
  padding-left: 20px;
  color: #4e93fb;
  cursor: pointer;
}
.frozen {
  padding-left: 20px;
  color: #fd5469;
  cursor: pointer;
}
.caidan {
  display: inline-block;
  padding: 2px 5px;
  border-radius: 4px;
  color: #4e93fb;
  background: #dce9fe;
}
.mulu {
  display: inline-block;
  padding: 2px 5px;
  border-radius: 4px;
  color: #009b06;
  background: #ccebcd;
}
.buttom {
  display: inline-block;
  padding: 2px 5px;
  border-radius: 4px;
  color: #fd5469;
  background: #f1c3de;
}
.new_page {
  margin: 20px auto;
  width: 100%;
  text-align: center;
}
</style>
